import React, { memo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { englishToBanglaNumber } from '../../../utils/helpers';

const DurationButtons = ({ unitArray, selectedUnit }) => {
  return (
    <div className="unit-btn">
      {unitArray.map((item, index) => {
        return (
          <Button
            key={index}
            size="sm"
            className={`btn-sm-customize ${item === '' ? 'empty-btn' : ''}`}
            variant="outline-secondary"
            onClick={() => selectedUnit('select', 'duration', item)}
          >
            {item}
          </Button>
        );
      })}
      <Form.Control
        type="text"
        style={{ width: '60px' }}
        size="sm"
        placeholder="যে কোন"
        onChange={(e) =>
          selectedUnit(
            'change',
            'duration',
            englishToBanglaNumber(e.target.value),
          )
        }
        onBlur={() => selectedUnit('clickOutside')}
      />
    </div>
  );
};
export default memo(DurationButtons);
