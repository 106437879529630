import React, { memo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { englishToBanglaNumber } from '../../../utils/helpers';

const ScheduleInputButtons = ({
  inputArr,
  scheduleIndex,
  setQuantitiesField,
}) => {
  const handleSchedule = (val, type) => {
    setQuantitiesField(scheduleIndex, englishToBanglaNumber(val), type);
  };
  return (
    <div className="unit-btn">
      {inputArr.map((item, index) => {
        return (
          <Button
            size="sm"
            className="btn-sm-customize"
            onClick={() => handleSchedule(item)}
            key={index}
            variant="outline-secondary"
          >
            {item}
          </Button>
        );
      })}
      <Form.Control
        type="text"
        style={{ width: '60px' }}
        size="sm"
        placeholder="যে কোন"
        onChange={(e) => handleSchedule(e.target.value, 'change')}
      />
    </div>
  );
};
export default memo(ScheduleInputButtons);
