import React, { useState, useEffect, Fragment, useRef, memo } from 'react';
import Header from '../../components/doctors/partials/Header';
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  ButtonGroup,
  Tab,
  Nav,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getRequest } from '../../utils/axiosRequests';
import { usePostRequest } from '../../hooks/usePostRequest';
import { usePatchRequest } from '../../hooks/usePatchRequest';
import { useValidation } from '../../hooks/validationHooks/useValiation';
import { appointmentSchema } from '../../validationSchemas/appointment.validation';
import LoadingButton from '../../components/forms/LoadingButton';
import { toast } from 'react-toastify';
import {
  userRole,
  ageConvertToDateTime,
  separateDateTime,
  agePrint,
  capitalizeFirstLetter,
} from '../../utils/helpers';
import TopLeftMenu from '../../components/doctors/partials/topLeftMenu';

import profileImg from '../../assets/images/profile.svg';
import BasicPatientForAppointment from '../../components/doctors/partials/basicPatientForAppointment';
import OthersPatientInfo from '../../components/doctors/partials/othersPatientInfo';
import WebcamCapture from '../../components/doctors/modals/WebcamCapture';
import { deleteRequest } from '../../utils/axiosRequests';
import MedicalPatientForAppointment from '../../components/doctors/partials/medicalPatientForAppointment';
import { useGetRequest } from '../../hooks/useGetRequest';

function AppointmentEdit() {
  const { state } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [appointmentDateTime, setAppointmentDateTime] = useState('');
  const [visitationFee, setVisitationFee] = useState('');
  const [backendError, setBackendError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOnExamination, setSelectedOnExamination] = useState({});

  const [patientPicture, setPatientPicture] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [patientInfo, setPatientInfo] = useState({});
  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [errors, setErrors] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(false);

  const validation = useValidation;

  const userInfo = useSelector((state) => state.authReducer.data);
  const activeOrganization = useSelector(
    (state) => state.orgReducer.organization,
  );
  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );
  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};

  let navigate = useNavigate();
  const onSuccess = (data) => {
    toast.success(data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate('/appointments');
  };
  const onError = (error) => {
    setLoadingStatus(false);
    setBackendError(error.message);
  };

  useEffect(() => {
    const appointmentInfo = state?.appointmentInfo;
    if (appointmentInfo) {
      setAppointmentInfo(appointmentInfo);
      const patientInfo = appointmentInfo?.patientId;
      setPatientInfo(patientInfo);
      setPhoneNumber(patientInfo?.phoneNumber);
      setSelectedOnExamination({
        ...selectedOnExamination,
        generalExaminations: {
          ...appointmentInfo?.otherDetails?.generalExaminations,
        },
      });
      setPatientId(patientInfo?.id);
      // setAppointmentId(appointmentInfo?.id);
      // setName(patientInfo?.name);
      // setPhoneNumber(patientInfo?.phoneNumber);
      // setGender(patientInfo?.gender);
      // setAddress(patientInfo?.patientDetails?.address);
      // setEmail(patientInfo?.email);
      // setAppointmentDateTime(
      //   moment(appointmentInfo?.appointmentDateTime).format('YYYY-MM-DD HH:mm'),
      // );
      // setVisitationFee(appointmentInfo?.paymentDetails?.amount ?? '');
      // setSerialNumber(appointmentInfo?.serialNumber);
      // setBirthDate(moment(patientInfo?.dob).format('YYYY-MM-DD'));
      // setDob({ ...separateDateTime(patientInfo?.dob) });
    }
  }, []);

  const { isLoading: isProfilePictureLoading, refetch: getProfilePicture } =
    useGetRequest(
      `get-patient-img1rfrfr/${patientId}`,
      `auth/patient-picture/${patientId}`,
      (data) => {
        setPatientPicture(data);
        console.log(data);
      },
      (e) => {
        console.log('error message: ', e.message);
      },
    );

  useEffect(() => {
    console.log('getting picturesdfs: ', patientId);
    if (patientId) getProfilePicture();
  }, [patientId]);

  const deletePatientImage = () => {
    if (patientPicture) {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this image?',
      );
      // Proceed with deletion if confirmed
      if (confirmDelete) {
        deleteRequest(`auth/patient-img-delete/${patientInfo.id}`)
          .then((res) => {
            console.log(res, 'deletePatientImage');
          })
          .catch((error) => {});
      }
    } else {
      setImageSrc(null);
    }
  };

  const { isPatientLoading, mutate: submitUser } = usePatchRequest(
    `appointments/update-appointment-with-patient/${appointmentInfo?.id}`,
    {
      patientId: patientInfo?.id,
      name: patientInfo.name,
      //dob: ageOrDob ? dob : ageConvertToDateTime(dob),
      dob: patientInfo.dob,
      phoneNumber: patientInfo.phoneNumber
        ? patientInfo.phoneNumber
        : phoneNumber,
      gender: patientInfo.gender ? patientInfo.gender : 'male',
      address: patientInfo?.patientDetails?.address
        ? patientInfo?.patientDetails?.address
        : '',
      email: patientInfo?.email ? patientInfo?.email : '',
      appointmentDateTime: appointmentDateTime,
      serialNumber: patientInfo?.serialNumber
        ? patientInfo?.serialNumber
        : appointmentInfo?.serialNumber,
      fee: visitationFee
        ? parseInt(visitationFee)
        : appointmentInfo?.paymentDetails?.amount,
      generalExaminations: selectedOnExamination?.generalExaminations,
      nid: patientInfo?.patientDetails?.nid,
      reference_by: appointmentInfo?.otherDetails?.reference_by,
      registration_no: patientInfo?.patientDetails?.registration_no,
      disease: appointmentInfo?.otherDetails?.disease,
      image: patientInfo?.image ? patientInfo?.image : null,
      doctorId:
        userRole(userInfo.userType) === 'assistant'
          ? activeDoctor.id
          : userInfo.id,
      organizationId:
        userRole(userInfo.userType) === 'assistant'
          ? userInfo?.organizationIds[0]?.id
          : activeOrganization.id,
    },
    onSuccess,
    onError,
  );
  const handleSubmit = () => {
    setLoadingStatus(true);
    const { isValid, errors } = validation(
      {
        ...patientInfo,
        birthDate: patientInfo?.dob,
        appointmentDateTime: appointmentDateTime,
      },
      appointmentSchema,
    );
    if (isValid) {
      console.log('scucess submit');
      console.log('data detais; ', patientInfo);
      console.log(
        'other info: ',
        visitationFee,
        appointmentDateTime,
        selectedOnExamination?.generalExaminations,
      );
      submitUser();
    }
    {
      if (Object.keys(errors).length !== 0) {
        setErrors(errors);
        setLoadingStatus(false);
      }
    }
  };

  const selectSearchResult = (selectedUser) => {
    setPatientInfo({ ...selectedUser[0] });
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            <TopLeftMenu />
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">Edit Appointment</h6>
              </div>

              <div className="card-body patient-history appointment-card-body">
                <Row>
                  <Col xs={3} sm={3} md={3}></Col>
                  <Col md={12} xs={12} sm={12} className="pl-4">
                    <Form className="appointmentForm mb-4">
                      <div className="v-error text-center">{backendError}</div>
                      <div className="add-new-rx mb-3">
                        <span>Edit Appointment</span>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12">
                          <div className="card shadow">
                            <div className="card-body patient-history patient-info">
                              <Row>
                                <Col
                                  md={3}
                                  className="patient-details pt-3"
                                  style={{ fontSize: '15px' }}
                                >
                                  <div className="icon-list">
                                    <i
                                      className="fa fa-plus-circle"
                                      onClick={() => setIsOpenCamera(true)}
                                    ></i>
                                    <i
                                      className="fa fa-edit"
                                      onClick={() => setIsOpenCamera(true)}
                                    ></i>
                                    {(patientPicture || imageSrc) && (
                                      <i
                                        className="fa fa-times-circle cursor-pointer"
                                        onClick={() => deletePatientImage()}
                                      ></i>
                                    )}
                                  </div>
                                  <div className="patient-img-info">
                                    {!isOpenCamera ? (
                                      <img
                                        className="profile-img"
                                        src={
                                          imageSrc ||
                                          patientPicture ||
                                          profileImg
                                        }
                                      />
                                    ) : (
                                      <>
                                        <WebcamCapture
                                          imageSrc={imageSrc}
                                          setImageSrc={setImageSrc}
                                          isOpenCamera={isOpenCamera}
                                          patientInfo={patientInfo}
                                          setPatientInfo={setPatientInfo}
                                          setIsOpenCamera={setIsOpenCamera}
                                        />
                                      </>
                                    )}
                                    <div className="font-weight-bold mt-2">
                                      {patientInfo?.name}
                                    </div>
                                    <div>{patientInfo?.phoneNumber}</div>
                                    <div>{patientInfo?.email}</div>
                                  </div>
                                  <hr />
                                  <Row className="text-center">
                                    <Col>
                                      <Row>
                                        <Col>
                                          <div>Gender</div>
                                          <div className="font-weight-bold mt-2">
                                            {capitalizeFirstLetter(
                                              patientInfo?.gender,
                                            )}
                                          </div>
                                          <hr />
                                        </Col>
                                        <Col>
                                          <div>Age</div>
                                          <div className="font-weight-bold mt-2">
                                            {agePrint(
                                              separateDateTime(
                                                patientInfo?.dob,
                                              ),
                                              {
                                                years: {
                                                  enabled: true,
                                                  label: 'Years',
                                                },
                                              },
                                            )}
                                            {/* {separateDateTime(
                                              patientInfo?.dob,
                                            ).toString()} */}
                                          </div>
                                          <hr />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div>Height</div>
                                          <div className="font-weight-bold mt-2">
                                            {
                                              selectedOnExamination
                                                ?.generalExaminations
                                                ?.heightInfo?.feet
                                            }
                                            {selectedOnExamination
                                              ?.generalExaminations?.heightInfo
                                              ?.inch
                                              ? '.' +
                                                selectedOnExamination
                                                  .generalExaminations
                                                  ?.heightInfo?.inch
                                              : ''}
                                            {selectedOnExamination
                                              ?.generalExaminations?.heightInfo
                                              ?.feet ||
                                            selectedOnExamination
                                              .generalExaminations?.heightInfo
                                              ?.inch
                                              ? ' ' +
                                                selectedOnExamination
                                                  .generalExaminations
                                                  ?.heightInfo?.heightUnit
                                              : ''}
                                          </div>
                                          <hr />
                                        </Col>
                                        <Col>
                                          <div>Weight</div>
                                          <div className="font-weight-bold mt-2">
                                            {selectedOnExamination
                                              ?.generalExaminations?.weightInfo
                                              ?.weight
                                              ? selectedOnExamination
                                                  .generalExaminations
                                                  ?.weightInfo?.weight +
                                                ' ' +
                                                selectedOnExamination
                                                  .generalExaminations
                                                  ?.weightInfo?.weightUnit
                                              : ''}
                                          </div>
                                          <hr />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={9} className="pl-4">
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="basic"
                                  >
                                    <Nav
                                      variant="pills"
                                      className="d-flex custom-tab"
                                      style={{
                                        alignItems: 'center',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Nav.Item className="flex-fill">
                                        <Nav.Link eventKey="basic">
                                          Basic Information
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item className="flex-fill">
                                        <Nav.Link eventKey="medical">
                                          Medical Information
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item className="flex-fill">
                                        <Nav.Link eventKey="others">
                                          Others
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                      <Tab.Pane eventKey="basic">
                                        <BasicPatientForAppointment
                                          patientInfo={patientInfo}
                                          setPatientInfo={setPatientInfo}
                                          selectSearchResult={
                                            selectSearchResult
                                          }
                                          setPhoneNumber={setPhoneNumber}
                                          appointmentInfo={appointmentInfo}
                                          setAppointmentDateTime={
                                            setAppointmentDateTime
                                          }
                                          setVisitationFee={setVisitationFee}
                                          isEdit={true}
                                          errors={errors}
                                        />
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="medical">
                                        <MedicalPatientForAppointment
                                          patientInfo={patientInfo}
                                          setPatientInfo={setPatientInfo}
                                          selectedOnExamination={
                                            selectedOnExamination
                                          }
                                          setSelectedOnExamination={
                                            setSelectedOnExamination
                                          }
                                          appointmentInfo={appointmentInfo}
                                        />
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="others">
                                        <OthersPatientInfo
                                          patientInfo={patientInfo}
                                          setPatientInfo={setPatientInfo}
                                          appointmentInfo={appointmentInfo}
                                          setAppointmentInfo={
                                            setAppointmentInfo
                                          }
                                        />
                                      </Tab.Pane>
                                      <Row className="mt-2 mb-2">
                                        <Col className="text-center">
                                          {isLoading ? (
                                            <LoadingButton btnFull="yes" />
                                          ) : (
                                            <Button
                                              variant="primary"
                                              size="sm"
                                              style={{ width: 'inherit' }}
                                              onClick={() => handleSubmit()}
                                            >
                                              <b>Done</b>
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </Tab.Content>
                                  </Tab.Container>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Col>
                  <Col nd={3}></Col>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default AppointmentEdit;
