import React, { useState, memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const OthersPatientInfo = (props) => {
  let { patientInfo, setPatientInfo, appointmentInfo, setAppointmentInfo } =
    props;
  const [errors, setErrors] = useState({});

  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  const handleSubmit = () => {};
  return (
    <div className="patient-info">
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label className="label-custom">NID</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter NID"
              defaultValue={patientInfo?.patientDetails?.nid}
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  patientDetails: {
                    ...patientInfo.patientDetails,
                    nid: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">{getErrorMessage('name')}</small>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="d-block label-custom">
              Reference By
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Reference By"
              defaultValue={appointmentInfo?.otherDetails?.reference_by}
              onChange={(e) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    reference_by: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label className="label-custom">Registration No</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Registration No"
              defaultValue={patientInfo?.patientDetails?.registration_no}
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  patientDetails: {
                    ...patientInfo.patientDetails,
                    registration_no: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">
              {getErrorMessage('registration_no')}
            </small>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="d-block label-custom">Disease</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Disease"
              defaultValue={appointmentInfo?.otherDetails?.disease}
              onChange={(e) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    disease: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">{getErrorMessage('disease')}</small>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default memo(OthersPatientInfo);
