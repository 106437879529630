import React, { memo, useState, useRef, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  InputGroup,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import {
  separateDateTime,
  ageConvertToDateTime,
  userRole,
} from '../../../utils/helpers';
import { getRequest } from '../../../utils/axiosRequests';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
// import { set } from 'react-datepicker/dist/date_utils';

const BasicPatientForAppointment = ({
  patientInfo,
  setPatientInfo,
  selectSearchResult,
  setPhoneNumber,
  appointmentInfo,
  isEdit,
  setAppointmentDateTime,
  setVisitationFee,
  errors,
}) => {
  const [ageOrDob, setAgeOrDob] = useState(false);
  const [birthDate, setBirthDate] = useState('');
  const [dob, setDob] = useState({
    years: '',
    months: '',
    days: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [searchResultArr, setSearchResultArr] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [isOldPatient, setSsOldPatient] = useState(false);
  const [phoneNumberTemp, setPhoneNumberTemp] = useState(
    patientInfo?.phoneNumber || '',
  );

  useEffect(() => {
    if (patientInfo) {
      setDob(separateDateTime(patientInfo?.dob));
      if (!patientInfo?.gender) {
        patientInfo['gender'] = 'male';
      }

      handleOnInputChange(patientInfo?.phoneNumber);
      setSelectedPatient({
        id: patientInfo?.id,
        phoneNumber: patientInfo?.phoneNumber,
      });
      setPhoneNumberTemp(patientInfo?.phoneNumber);
      setAppointmentDateTime(
        moment(appointmentInfo.appointmentDateTime).format('YYYY-MM-DD HH:mm'),
      );
      setVisitationFee(
        appointmentInfo?.paymentDetails?.amount
          ? appointmentInfo?.paymentDetails?.amount
          : '',
      );
    }
  }, [phoneNumberTemp]);

  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );

  const handleAge = (fieldName, val) => {
    if (fieldName === 'months' && val >= 12) {
      alert('Months must be less than 12');
      return false;
    }
    if (fieldName === 'days') {
      let daysOfMonth = dob?.months
        ? moment().month(dob?.months).daysInMonth()
        : moment().daysInMonth();
      if (val > daysOfMonth) {
        alert(`Days must be less than ${daysOfMonth}`);
        return false;
      }
    }
    if (fieldName === 'dob') {
      const dob = separateDateTime(val);
      setDob({ ...dob });
      setPatientInfo({ ...patientInfo, dob: val });
    } else {
      const dobData = { ...dob, [fieldName]: val };
      setDob({ ...dobData });
      setPatientInfo({ ...patientInfo, dob: ageConvertToDateTime(dobData) });
    }
  };

  const getPatientSearchUrl = (val) => {
    if (activeDoctor) {
      return `patients?phoneNumber=${val}&doctorId=${activeDoctor.id}`;
      // return `patients?phoneNumber=${val}`;
    } else {
      return `patients?phoneNumber=${val}`;
    }
  };

  const typeaheadRef = useRef(null);
  const filterBy = () => true;
  const handleOnInputChange = (val) => {
    setIsLoading(true);
    const url = getPatientSearchUrl(val);
    setPhoneNumber(val);

    getRequest(url)
      .then((data) => {
        setSearchResultArr(data);
        // setSelectedPatient(data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleType = (val) => {
    setAgeOrDob(val);
    if (!val) {
      setDob(separateDateTime(patientInfo.dob));
    } else {
    }
  };

  const fetchedOptions = [
    { id: 1, phoneNumber: 'Option 1' },
    { id: 2, phoneNumber: 'Option 2' },
  ];

  return (
    <div className="patient-info">
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Phone No.</Form.Label>
            <AsyncTypeahead
              labelKey="phoneNumber"
              ref={typeaheadRef}
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              options={searchResultArr}
              {...(isEdit
                ? {
                    selected: [
                      searchResultArr.length > 0
                        ? searchResultArr[0]
                        : { id: '', phoneNumber: '' },
                    ],
                  }
                : {})}
              // selected={[
              //   searchResultArr.length > 0
              //     ? searchResultArr[0]
              //     : { id: '', phoneNumber: '' },
              // ]}
              placeholder={'Search / Add patient here ...'}
              onChange={selectSearchResult}
              // onClick={selectSearchResult}
              minLength={5}
              onSearch={handleOnInputChange}
              // size="md"
              className="sm md"
              renderMenuItemChildren={(option, props) => (
                <Fragment>
                  <span>{option.phoneNumber}</span>
                </Fragment>
              )}
            />
            <small className="v-error">{getErrorMessage('phoneNumber')}</small>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mt-2">
            <Form.Label className="label-custom">Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter name"
              defaultValue={patientInfo?.name}
              onChange={(e) =>
                setPatientInfo({ ...patientInfo, name: e.target.value })
              }
            />
            <small className="v-error">{getErrorMessage('name')}</small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label style={{ display: 'block', marginTop: '7px' }}>
              <span>Age</span>
              <ButtonGroup
                className="appointment-btn-group"
                aria-label="Basic example"
              >
                <Button
                  size="sm"
                  variant={!ageOrDob ? 'primary' : 'outline-secondary'}
                  onClick={() => handleType(false)}
                >
                  Age
                </Button>
                <Button
                  size="sm"
                  variant={ageOrDob ? 'primary' : 'outline-secondary'}
                  onClick={() => handleType(true)}
                >
                  DOB
                </Button>
              </ButtonGroup>
            </Form.Label>
            {!ageOrDob && (
              <InputGroup>
                <Form.Control
                  type="number"
                  defaultValue={dob?.years}
                  placeholder="Year"
                  onChange={(e) => handleAge('years', e.target.value)}
                />
                <Form.Control
                  type="number"
                  defaultValue={dob?.months}
                  placeholder="Month"
                  onChange={(e) => handleAge('months', e.target.value)}
                />
                <Form.Control
                  type="number"
                  defaultValue={dob?.days}
                  placeholder="Day"
                  onChange={(e) => handleAge('days', e.target.value)}
                />
              </InputGroup>
            )}
            {ageOrDob && (
              <Form.Control
                type="date"
                placeholder="Enter age"
                value={birthDate}
                data-date-format="DD MMMM YYYY"
                onChange={
                  (e) => handleAge('dob', e.target.value)
                  // setBirthDate(e.target.value)
                }
              />
            )}
            <small className="v-error">{getErrorMessage('birthDate')}</small>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              className="form-control"
              value={patientInfo?.gender}
              onChange={(e) =>
                setPatientInfo({ ...patientInfo, gender: e.target.value })
              }
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="others">Others</option>
            </Form.Select>
            <small className="v-error">{getErrorMessage('gender')}</small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label-custom">Email</Form.Label>
            <Form.Control
              size="sm"
              type="email"
              placeholder="Enter email"
              defaultValue={patientInfo?.email}
              onChange={(e) =>
                setPatientInfo({ ...patientInfo, email: e.target.value })
              }
            />
            <small className="v-error">{getErrorMessage('email')}</small>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label-custom">Address</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter address"
              defaultValue={patientInfo?.patientDetails?.address}
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  patientDetails: {
                    ...patientInfo.patientDetails,
                    address: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">{getErrorMessage('address')}</small>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Appointment Date</Form.Label>
            <Form.Control
              type="datetime-local"
              defaultValue={
                appointmentInfo.appointmentDateTime
                  ? moment(appointmentInfo.appointmentDateTime).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : ''
              }
              onChange={(e) =>
                setAppointmentDateTime(
                  moment(e.target.value).format('YYYY-MM-DD HH:mm'),
                )
              }
            />
            <small className="v-error">
              {getErrorMessage('appointmentDateTime')}
            </small>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="d-block label-custom">
              <span>Visitation Fee</span>
              <ButtonGroup
                className="appointment-btn-group pb-2"
                aria-label="Basic example"
              >
                <Button
                  size="sm"
                  variant={!isOldPatient ? 'primary' : 'outline-secondary'}
                  onClick={() => isOldPatient(false)}
                >
                  New
                </Button>
                <Button
                  size="sm"
                  variant={isOldPatient ? 'primary' : 'outline-secondary'}
                  onClick={() => isOldPatient(true)}
                >
                  Old
                </Button>
              </ButtonGroup>
            </Form.Label>
            <Form.Control
              size="sm"
              type="number"
              placeholder="Enter visitation fee"
              defaultValue={
                appointmentInfo?.paymentDetails?.amount
                  ? appointmentInfo?.paymentDetails?.amount
                  : ''
              }
              onChange={(e) => {
                setVisitationFee(e.target.value);
                // setPatientInfo({
                //   ...patientInfo,
                //   visitationFee: e.target.value,
                // });
              }}
            />
            <small className="v-error">
              {getErrorMessage('visitationFee')}
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="label-custom">Serial Number</Form.Label>
            <Form.Control
              size="sm"
              type="number"
              defaultValue={
                appointmentInfo?.serialNumber
                  ? appointmentInfo?.serialNumber
                  : ''
              }
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  serialNumber: e.target.value,
                })
              }
            />
            <small className="v-error">{getErrorMessage('serialNumber')}</small>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default memo(BasicPatientForAppointment);
