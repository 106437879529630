import { memo } from 'react';

const ArrowIcon = ({ value, action, fieldName }) => {
  return (
    <span className="arrow-flex">
      <span
        class="icon icon-up"
        onClick={(e) => {
          e.stopPropagation();
          action(value, fieldName, 'next');
        }}
      ></span>
      <span
        class="icon icon-down"
        onClick={(e) => {
          e.stopPropagation();
          action(value, fieldName, 'prev');
        }}
      ></span>
    </span>
  );
};

export default memo(ArrowIcon);
